<template>
  <FullScreenModal
    ref="modal"
    modal-class="merchant-account-modal"
    :title="`${editing ? 'Edit' : 'Enable'} Club Merchant Account`"
    is-all-white>
    <template #header>
      <FormModalHeader
        :actions="['more-actions', 'save']"
        @dismiss="dismiss({ refreshData: true })">
        <template #title>
          <div class="is-flex is-align-items-center">
            <div class="is-size-4 has-text-weight-bold is-font-mont">{{ title }}</div>
            <b-tag
              v-if="isDefault"
              rounded
              type="is-info"
              class="badge"
            >
              Default
            </b-tag>
            <b-tag
              v-if="isSecondary"
              rounded
              type="is-info"
              class="badge"
            >
              Secondary
            </b-tag>
          </div>
        </template>
        <template v-if="showMoreActions" #more-actions>
          <b-dropdown
            position="is-bottom-left"
            aria-role="menu"
          >
            <template #trigger>
              <button
                class="button is-primary is-outlined"
                role="button"
                :disabled="!!saving || !isEnabled"
              >
                <span>More Actions</span>
                <span class="icon">
                  <fa-icon :icon="['far', 'chevron-down']" :transform="'shrink-3'"/>
                </span>
              </button>
            </template>
            <b-dropdown-item
              v-if="allowCreateSecondaryAccount"
              aria-role="menuitem"
              :disabled="!!saving || !isEnabled"
              @click="createSecondaryAccount"
            >
              Create Secondary Account
            </b-dropdown-item>
            <b-dropdown-item
              v-if="allowCreateAdditionalMerchantAccount"
              aria-role="menuitem"
              :disabled="!!saving || !isEnabled"
              @click="addAdditionalMerchantAccount"
            >
              Create Additional Merchant Account
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #save>
          <b-button
            type="is-primary"
            :disabled="!!saving"
            :loading="saving === 'save'"
            @click="save">
            Save
          </b-button>
        </template>
      </FormModalHeader>
    </template>
    <div class="centered-tool">
      <div class="form-section">
        <p class="form-section__label">Club</p>
        <b-field>
          <b-input :value="clubName" v-if="!allowClubSelect" disabled/>
          <b-autocomplete
            v-else
            v-model="query"
            :data="filteredClubs"
            field="name"
            append-to-body
            placeholder="Search for Club"
            @select="assignClub($event)">
            <template #empty>No results found</template>
          </b-autocomplete>
        </b-field>
        <b-switch v-model="showTestClubs" v-if="allowClubSelect">Show Test Clubs</b-switch>
      </div>
      <template v-if="clubName">
        <div class="form-section">
          <p class="form-section__label">Vendor</p>
          <b-field>
            <b-radio v-model="merchantAccount.vendor"
              name="vendor"
              native-value="Stripe"
              :disabled="editing">Stripe</b-radio>
            <b-radio v-model="merchantAccount.vendor"
              v-if="editing"
              name="vendor"
              native-value="WePay"
              :disabled="editing">
              WePay
            </b-radio>
          </b-field>
        </div>
        <div class="form-section">
          <p class="form-section__label">Country</p>
          <b-field>
            <b-radio v-model="merchantAccount.country" native-value="US" :disabled="editing">United States</b-radio>
            <b-radio v-model="merchantAccount.country" native-value="CA" :disabled="editing">Canada</b-radio>
          </b-field>
        </div>
        <div class="form-section">
          <div class="columns">
            <div class="column">
              <p class="form-section__label">Controller</p>
              <b-field>
                <b-select v-model="merchantAccount.user_id"
                          :placeholder="clubAdmins.length ? 'Select an Admin' : 'No Admins found'"
                          :disabled="clubAdmins.length === 0"
                          :loading="loadingControllers">
                  <option v-for="i in clubAdmins" :value="i.id" :key="i.id">{{i.name}} - {{i.email}}</option>
                </b-select>
              </b-field>
            </div>
            <div v-if="isWePay" class="column">
              <b-field>
                <template v-slot:label>
                  <p class="form-section__label">
                    {{ kycFlag.name }}
                    <b-tooltip
                      :label="kycFlag.tooltip"
                      position="is-bottom"
                      multilined>
                      <span class="has-text-info is-size-6">
                        <fa-icon :icon="['fas', 'info-circle']" />
                      </span>
                    </b-tooltip>
                  </p>
                </template>
                <div class="mt-3">
                  <b-switch
                    v-model="merchantAccount.flags"
                    :native-value="kycFlag.value">{{ isKycEnabled ? 'Enabled' : 'Disabled' }}</b-switch>
                </div>
              </b-field>
            </div>
          </div>
          <div v-if="isWePay" class="columns">
            <div class="column">
              <div>
                <b-checkbox
                  v-model="merchantAccount.flags"
                  :disabled="isForceSsnLast4 && !isForceSsnFull"
                  :native-value="ssnFullFlag.value">{{ ssnFullFlag.name }}</b-checkbox>
              </div>
              <div>
                <b-checkbox
                  v-model="merchantAccount.flags"
                  :disabled="isForceSsnFull && !isForceSsnLast4"
                  :title="isForceSsnFull ? ssnLast4Flag.tooltip : null"
                  :native-value="ssnLast4Flag.value">{{ ssnLast4Flag.name }}</b-checkbox>
              </div>
              <div>
                <b-checkbox
                  v-model="merchantAccount.flags"
                  :native-value="forceDobFlag.value">{{ forceDobFlag.name }}</b-checkbox>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="form-section">
          <div class="is-font-mont has-text-weight-semibold is-size-4">Transaction Fees</div>
          <div class="is-italic mb-4">
            These are the fees to charge the <span class="has-text-weight-bold">Club</span> for each transaction.
            Check with Sales before changing these fees.
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Base Fee (in cents)">
                <b-input v-model.number="merchantAccount.fee_base_cents"
                  type="number"
                  min="0"
                  max="1000" />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Additional Percentage (0.029 is 2.9%)">
                <b-input v-model.number="merchantAccount.fee_percent"
                  type="number"
                  step="0.0001"
                  min="0"
                  max="0.20"/>
              </b-field>
            </div>
          </div>
  <!--        <div class="columns">-->
  <!--          <div class="column">-->
  <!--            <b-field label="Debit Fee (in cents)">-->
  <!--              <b-input v-model.number="merchantAccount.debit_base_cents" type="number" min="0" max="1000"/>-->
  <!--            </b-field>-->
  <!--          </div>-->
  <!--          <div class="column">-->
  <!--            <b-field label="Debit % (0.029 is 2.9%)">-->
  <!--              <b-input v-model.number="merchantAccount.debit_percent"-->
  <!--                       type="number" step="0.0001" min="0" max="0.20"/>-->
  <!--            </b-field>-->
  <!--          </div>-->
  <!--          <div class="column">-->
  <!--            <b-field label="Debit Cap (in cents)">-->
  <!--              <b-input v-model.number="merchantAccount.debit_cap" type="number" min="0" max="1000"/>-->
  <!--            </b-field>-->
  <!--          </div>-->
  <!--        </div>-->
          <div class="columns">
            <div class="column">
              <b-field label="ACH Fee (in cents)">
                <b-input v-model.number="merchantAccount.ach_base_cents"
                  type="number"
                  min="0"
                  max="1000"
                  :disabled="!allowACH"/>
              </b-field>
            </div>
            <div class="column">
              <b-field label="ACH % (0.015 is 1.5%)">
                <b-input v-model.number="merchantAccount.ach_percent"
                  type="number"
                  step="0.0001"
                  min="0"
                  max="0.20"
                  :disabled="!allowACH"/>
              </b-field>
            </div>
            <div class="column">
              <b-field label="ACH Cap (in cents)">
                <b-input v-model.number="merchantAccount.ach_cap"
                  type="number"
                  min="0"
                  max="1000"
                  :disabled="!allowACH"/>
              </b-field>
            </div>
          </div>
          <div class="form-section">
            <div
              class="is-font-mont has-text-weight-bold my-4">
              Transaction Fee Calculation
              </div>
            <b-field>
              <b-radio v-model="matchFees" :native-value="false">
                Include the transaction fee in the calculation of the fee to charge the club
              </b-radio>
            </b-field>
            <b-field class="mb-0">
              <b-radio v-model="matchFees" :native-value="true">
                Do <span class="has-text-weight-bold">NOT</span> include the transaction fee in the calculation
                of the fee to charge the club
              </b-radio>
            </b-field>
            <div class="is-italic is-size-7 ml-5">
              Only use this option in situations where we want the club to receive the actual amount the club charges
              the billing account. Ex. team account fees
            </div>
          </div>
          <div class="form-section">
            <div class="is-font-mont has-text-weight-bold">Debit Cards</div>
            <div class="is-italic mb-4">
              Indicate whether the club is charged a fee for debit card transactions
            </div>
            <b-field>
              <b-radio v-model="chargeClubDebitFees" :native-value="true">
                Charge the club the calculated transaction fee
              </b-radio>
            </b-field>
            <b-field class="mb-0">
              <b-radio v-model="chargeClubDebitFees" :native-value="false">
                Do <span class="has-text-weight-bold">NOT</span> charge the club the calculated transaction fee
              </b-radio>
            </b-field>
            <div class="is-italic is-size-7 ml-5">
              Only use this option in situations where the club does not charge for debit cards AND we do not want to
              charge the club the calculated transaction fee. Ex. team account fees
            </div>
          </div>
        </div>
        <hr>
        <div class="form-section">
          <div class="is-font-mont has-text-weight-semibold is-size-4">Service Fees</div>
          <div class="is-italic mb-4">
            These are the fees to charge the <span class="has-text-weight-bold">Billing Account</span> for each transaction.
            Verify with the club before changing these fees.
          </div>
          <div class="form-section">
            <div class="is-font-mont has-text-weight-semibold is-size-5 mb-4">Card Service Fees</div>
            <b-field>
              <b-radio
                v-model="cardFees.fee_type"
                native-value="">
                Do not charge the billing account a service fee
              </b-radio>
            </b-field>
            <b-field>
              <b-radio
                v-model="cardFees.fee_type"
                native-value="standard">
                Charge the billing account the transaction fee charged to the club
              </b-radio>
            </b-field>
            <b-field>
              <b-radio
                v-model="cardFees.fee_type"
                native-value="custom">
                Charge the billing account a specific service fee
              </b-radio>
            </b-field>
            <div v-if="cardFees.fee_type !== ''">
              <div class="is-font-mont has-text-weight-bold mt-5 mb-4">Fee Name</div>
              <b-input v-model="cardFees.fee_name"/>
              <div class="is-font-mont has-text-weight-bold mt-5 mb-4">
                Fee to Charge the Billing Account per Transaction
              </div>
              <div class="columns is-mobile">
                <div class="column">
                  <div>Percentage</div>
                  <div class="columns is-mobile is-vcentered">
                    <div class="column is-narrow input-money">
                      <b-input
                        v-if="!isCustomCardFee"
                        :value="merchantAccount.fee_percent"
                        :disabled="true"
                      />
                      <b-input
                        v-else
                        v-model.number="cardFeeAsPercent"
                        type="number"
                      />
                    </div>
                    <div class="column is-narrow has-text-weight-medium pl-0">%</div>
                  </div>
                </div>
                <div class="column">
                  <div class="ml-5">Fixed Fee</div>
                  <div class="columns is-mobile is-vcentered">
                    <div class="column is-narrow has-text-weight-medium pr-0">$</div>
                    <div class="column is-narrow">
                      <InputMoney
                        v-if="!isCustomCardFee"
                        :value="merchantAccount.fee_base_cents"
                        :has-icon="false"
                        :disabled="true"/>
                      <InputMoney
                        v-else
                        v-model="cardFees.fee_base_cents"
                        :has-icon="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="cardFees.fee_type !== ''">
                <div class="is-font-mont has-text-weight-bold">Debit Card Service Fee Exceptions</div>
                <div class="is-italic mb-4">
                  Check this box if the club is not allowed to charge the billing account
                </div>
                <b-field>
                  <b-checkbox v-model="noServiceFeesForDebit">
                    No Service Fees for Debit Cards (Colorado clubs)
                  </b-checkbox>
                </b-field>
              </div>
            </div>
          </div>
          <div class="form-section" v-if="allowACH && isStripe">
            <div class="is-font-mont has-text-weight-semibold is-size-5 mb-4">ACH Service Fees</div>
            <b-field>
              <b-radio
                v-model="achFees.fee_type"
                native-value="">
                Do not charge the billing account a service fee
              </b-radio>
            </b-field>
            <b-field>
              <b-radio
                v-model="achFees.fee_type"
                native-value="standard">
                Charge the billing account the transaction fee charged to the club
              </b-radio>
            </b-field>
            <b-field>
              <b-radio
                v-model="achFees.fee_type"
                native-value="custom">
                Charge the billing account a specific service fee
              </b-radio>
            </b-field>
            <div v-if="achFees.fee_type !== ''">
              <div class="is-font-mont has-text-weight-bold mt-5 mb-4">Fee Name</div>
              <b-input v-model="achFees.fee_name"/>
              <div class="is-font-mont has-text-weight-bold mt-5 mb-4">
                Fee to Charge the Billing Account per Transaction
              </div>
              <div class="columns is-mobile">
                <div class="column">
                  <div>Percentage</div>
                  <div class="columns is-mobile is-vcentered">
                    <div class="column is-narrow input-money">
                      <b-input
                        v-if="!isCustomACHFee"
                        :value="achFeeAsPercent"
                        :disabled="true"
                      />
                      <b-input
                        v-else
                        v-model.number="achFeeAsPercent"
                        type="number"
                      />
                    </div>
                    <div class="column is-narrow has-text-weight-medium pl-0">%</div>
                  </div>
                </div>
                <div class="column">
                  <div class="ml-5">Fixed Fee</div>
                  <div class="columns is-mobile is-vcentered">
                    <div class="column is-narrow has-text-weight-medium pr-0">$</div>
                    <div class="column is-narrow">
                      <InputMoney
                        v-if="!isCustomACHFee"
                        :value="merchantAccount.ach_base_cents"
                        :has-icon="false"
                        :disabled="true"/>
                      <InputMoney
                        v-else
                        v-model="achFees.fee_base_cents"
                        :has-icon="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="ml-5">Cap ($0.00 means no cap)</div>
                  <div class="columns is-mobile is-vcentered">
                    <div class="column is-narrow has-text-weight-medium pr-0">$</div>
                    <div class="column is-narrow">
                      <InputMoney
                        v-if="!isCustomACHFee"
                        :value="merchantAccount.ach_cap"
                        :has-icon="false"
                        :disabled="true"/>
                      <InputMoney
                        v-else
                        v-model="achFees.fee_cap"
                        :has-icon="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="form-section">
          <p class="form-section__label">Flags</p>
          <div class="checkboxes">
            <template v-for="flag in flagOptions">
              <b-field :key="flag.value" v-if="!flag.standalone">
                <b-checkbox v-model="merchantAccount.flags" :native-value="flag.value" :disabled="disableFlagInput(flag.value)">
                  {{flag.name}}
                </b-checkbox>
              </b-field>
            </template>
          </div>
        </div>
      </template>
    </div>
    <AddAdditionalMerchantAccount
      ref="addAdditionalModal"
      @add="doAddAdditionalMerchantAccount"
    />
  </FullScreenModal>
</template>
<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import FullScreenModal from '@/components/layout/modals/FullScreenModal';
import FormModalHeader from '@/components/layout/modals/FormModalHeader';
import InputMoney from '@/components/common/form-elements/InputMoney';
import AddAdditionalMerchantAccount from '@/components/merchant/AddAdditionalMerchantAccount';

export default {
  components: {
    FullScreenModal,
    FormModalHeader,
    InputMoney,
    AddAdditionalMerchantAccount,
  },
  props: {
    clubs: { type: Array, default: () => [] },
    enableableClubs: { type: Array, default: () => [] },
  },
  data() {
    return {
      clubID: null,
      clubName: null,
      allowClubSelect: false,
      setAsDefault: false,
      existingAccountId: null,
      existingAccountName: null,
      editing: false,
      loadingControllers: false,
      showTestClubs: false,
      saving: null,
      clubAdmins: [],
      query: '',
      merchantAccount: {
        vendor: null,
        country: 'US',
        user_id: null,
        name: '',
        fee_base_cents: 30,
        fee_percent: 0.029,
        debit_base_cents: 30,
        debit_percent: 0.029,
        debit_cap: 0,
        ach_base_cents: 0,
        ach_percent: 0.015,
        ach_cap: 0,
        flags: [] },
      hasClubSecondaryAccount: false,
      cardFees: {
        fee_type: '',
        fee_name: 'Service Fee',
        fee_base_cents: 0,
        fee_percent: 0.0,
      },
      achFees: {
        fee_type: '',
        fee_name: 'Service Fee',
        fee_base_cents: 0,
        fee_percent: 0.0,
        fee_cap: 0,
      },
      allClubAccounts: [],
      noServiceFeesForDebit: false,
      chargeClubDebitFees: true,
      matchFees: false,
      cardFeeAsPercent: 0.0,
      achFeeAsPercent: 0.0,
    };
  },
  computed: {
    title() {
      return `${this.editing ? 'Edit' : 'Enable'} Club Merchant Account`;
    },
    flagOptions() {
      return [{ value: 'DisallowAmex', name: 'Disallow Amex' },
        { value: 'DisallowDebit', name: 'Disallow Debit' },
        { value: 'AllowACH', name: 'Allow ACH (Stripe Only)' },
        // { value: 'Treasury', name: 'Treasury (Stripe Only)' },
        { value: 'Disabled', name: 'Disabled' },
        { value: 'Archived', name: 'Archived' },
        { value: 'CollectSsnFull', name: 'Force collection of Full SSN', standalone: true },
        { value: 'CollectSsnLast4', name: 'Force collection of SSN last 4 digits', standalone: true },
        { value: 'CollectDob', name: 'Force collection of full Birth Date', standalone: true },
        { value: 'EnableKyc',
          name: 'Enable $1.2M+ KYC flow',
          standalone: true,
          tooltip: 'This flow supports gathering data for WePay clubs that are non-profits or unincorporated entities and process over $1.2M' }];
    },
    kycFlag() {
      return this.flagOptions.find(f => f.value === 'EnableKyc');
    },
    isStripe() {
      return this.merchantAccount.vendor === 'Stripe';
    },
    isWePay() {
      return this.merchantAccount.vendor === 'WePay';
    },
    allowACH() {
      return this.merchantAccount.flags.includes('AllowACH');
    },
    isDefault() {
      return this.merchantAccount.is_default;
    },
    isSecondary() {
      return this.merchantAccount.flags.includes('Secondary');
    },
    isEnabled() {
      return this.merchantAccount.flags.includes('Enabled');
    },
    isKycEnabled() {
      return this.merchantAccount.flags.includes(this.kycFlag.value);
    },
    filteredClubs() {
      return this.enableableClubs.filter(club => (!club.is_test_club || this.showTestClubs)
        && club.name.toLowerCase().includes(this.query.toLowerCase()));
    },
    club() {
      return this.clubs.find(club => club.id === this.clubID);
    },
    allowCreateSecondaryAccount() {
      return this.editing
        && this.merchantAccount.vendor === 'Stripe'
        && !this.isSecondary
        && !this.hasClubSecondaryAccount;
    },
    allowCreateAdditionalMerchantAccount() {
      return this.editing
        && !this.isSecondary;
    },
    showMoreActions() {
      return this.allowCreateSecondaryAccount
        || this.allowCreateAdditionalMerchantAccount;
    },
    isCustomCardFee() {
      return this.cardFees.fee_type === 'custom';
    },
    isCustomACHFee() {
      return this.achFees.fee_type === 'custom';
    },
    forceDobFlag() {
      return this.flagOptions.find(f => f.value === 'CollectDob');
    },
    ssnFullFlag() {
      return this.flagOptions.find(f => f.value === 'CollectSsnFull');
    },
    ssnLast4Flag() {
      return this.flagOptions.find(f => f.value === 'CollectSsnLast4');
    },
    isForceSsnFull() {
      return this.merchantAccount.flags.includes(this.ssnFullFlag.value);
    },
    isForceSsnLast4() {
      return this.merchantAccount.flags.includes(this.ssnLast4Flag.value);
    },
  },
  methods: {
    show(cm, allClubAccounts) {
      if (cm) {
        this.editing = true;
        this.allowClubSelect = false;
        this.clubID = cm.club_id;
        this.clubName = cm.club_name;
        this.merchantAccount = cm;
        this.cardFees = _.cloneDeep(cm.card_fees);
        this.achFees = _.cloneDeep(cm.ach_fees);
        this.cardFeeAsPercent = (this.cardFees.fee_percent * 100.0).toFixed(2);
        this.achFeeAsPercent = (this.achFees.fee_percent * 100.0).toFixed(2);
        this.noServiceFeesForDebit = cm.debit_card_fees?.fee_type === 'custom' && !cm.debit_card_fees?.fee_base_cents
          && !cm.debit_card_fees?.fee_percent;
        this.chargeClubDebitFees = !!cm.debit_base_cents || !!cm.debit_percent;
        this.loadControllerOptions(cm.club_id);
        this.matchFees = !!cm.match_fees && !!cm.match_debit_fees && !!cm.match_ach_fees;
      } else {
        this.editing = false;
        this.allowClubSelect = true;
        this.clubID = null;
        this.clubName = null;
        this.merchantAccount = {
          vendor: 'Stripe',
          country: 'US',
          user_id: null,
          name: '',
          fee_base_cents: 30,
          fee_percent: 0.029,
          debit_base_cents: 30,
          debit_percent: 0.029,
          debit_cap: 0,
          ach_base_cents: 0,
          ach_percent: 0.015,
          ach_cap: 0,
          flags: [],
          alternate_payment_flow: true,
        };
        this.cardFees = {
          fee_type: '',
          fee_name: 'Service Fee',
          fee_base_cents: 0,
          fee_percent: 0.0,
        };
        this.achFees = {
          fee_type: '',
          fee_name: 'Service Fee',
          fee_base_cents: 0,
          fee_percent: 0.0,
          fee_cap: 0,
        };
        this.cardFeeAsPercent = (this.cardFees.fee_percent * 100.0).toFixed(2);
        this.achFeeAsPercent = (this.achFees.fee_percent * 100.0).toFixed(2);
        this.noServiceFeesForDebit = false;
        this.chargeClubDebitFees = true;
        this.matchFees = false;
      }
      this.allClubAccounts = allClubAccounts || [];
      this.hasClubSecondaryAccount = _.some(allClubAccounts, a => a.flags.includes('Secondary'));
      this.setAsDefault = false;
      this.existingAccountId = null;
      this.existingAccountName = null;
      this.$refs.modal.show();
    },
    assignClub(club) {
      if (club) {
        this.clubID = club.id;
        this.clubName = club.name;
        this.loadControllerOptions(club.id);
      }
    },
    checkForCanManageFinancials(user) {
      // check for system admin with Can Manage Financials
      if (user.current_role_permissions_expanded?.includes('club_administration') &&
        user.current_role_permissions_expanded?.includes('manage_program_financials')) {
        return true;
      }
      if (user.club_regions?.some(cr => cr.club_region_id === 0 && cr.permissions_expanded.includes('manage_program_financials'))) {
        return true;
      }
      return false;
    },
    loadControllerOptions(id) {
      this.loadingControllers = true;
      api().get(`/cst/clubs/${id}/admins?populate=permissions`).then((res) => {
        // Only show system admins with financial access.
        this.clubAdmins = res.data
          .filter(this.checkForCanManageFinancials)
          .map(i => ({ ...i, name: `${i.first_name} ${i.last_name}` }));
      }).finally(() => {
        this.loadingControllers = false;
      });
    },
    disableFlagInput(flag) {
      if (flag === 'DisallowDebit') {
        return this.isSecondary;
      } else if (flag === 'AllowACH' || flag === 'Treasury') {
        return !this.isStripe;
      }
      return false;
    },
    toast(message, success = true) {
      this.$store.commit('setNotification', { toast: true, success, message });
    },
    createSecondaryAccount() {
      const message = `This will create a Secondary Merchant Account and enable Team Accounts for <b>${this.clubName}</b>.
      <br><br>Are you sure you want to proceed?`;
      this.$buefy.dialog.confirm({
        title: 'Create Secondary Merchant Account',
        message,
        confirmText: 'Create Secondary Account',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.doCreateSecondary(),
      });
    },
    doCreateSecondary() {
      this.saving = 'secondary';
      api().post(`/cst/merchant_accounts/${this.merchantAccount.id}/create_secondary`).then((res) => {
        if (res.status === 200) {
          this.toast('Secondary Account Created');
          this.dismiss({ refreshData: true });
        }
      }).finally(() => {
        this.saving = null;
      });
    },
    addAdditionalMerchantAccount() {
      this.$refs.addAdditionalModal.showModal(this.club, this.allClubAccounts);
    },
    async doAddAdditionalMerchantAccount(options) {
      const existingMerchantAccount = this.merchantAccount;
      this.show(null, this.allClubAccounts);
      this.clubID = existingMerchantAccount.club_id;
      this.clubName = existingMerchantAccount.club_name;
      this.allowClubSelect = false;
      this.setAsDefault = options.setAsDefault;
      this.merchantAccount.name = options.newAccountName;
      if (!_.isEmpty(options.newPrimaryAccountName)) {
        this.existingAccountName = options.newPrimaryAccountName;
        this.existingAccountId = existingMerchantAccount.id;
      }
    },
    async save() {
      this.saving = 'save';
      const toPost = _.pick(this.merchantAccount, ['vendor', 'country',
        'user_id',
        'fee_base_cents',
        'fee_percent',
        'debit_base_cents',
        'debit_percent',
        'debit_cap',
        'ach_base_cent',
        'ach_percent',
        'ach_cap',
        'alternate_payment_flow',
      ]);
      toPost.flags = this.merchantAccount.flags.filter(f => this.flagOptions.some(opt => opt.value === f));
      if (this.isForceSsnFull) {
        toPost.flags = _.without(toPost.flags, this.ssnLast4Flag.value);
      }

      if (this.cardFees.fee_type === 'custom') {
        this.cardFees.fee_percent = Number((this.cardFeeAsPercent / 100.0).toFixed(4));
      }

      if (this.achFees.fee_type === 'custom') {
        this.achFees.fee_percent = Number((this.achFeeAsPercent / 100.0).toFixed(4));
      }

      if (!_.isEmpty(this.merchantAccount.name)) {
        toPost.name = this.merchantAccount.name;
      }

      toPost.card_fees = this.cardFees;
      toPost.ach_fees = this.achFees;
      if (this.noServiceFeesForDebit) {
        toPost.debit_card_fees = {
          fee_type: 'custom',
          fee_name: 'Service Fee',
          fee_base_cents: 0,
          fee_percent: 0.0,
          fee_cap: 0,
        };
      } else {
        toPost.debit_card_fees = this.cardFees;
      }

      if (this.chargeClubDebitFees) {
        toPost.debit_base_cents = toPost.fee_base_cents;
        toPost.debit_percent = toPost.fee_percent;
      } else {
        toPost.debit_base_cents = 0;
        toPost.debit_percent = 0;
      }

      if (this.matchFees) {
        toPost.match_fees = true;
        toPost.match_debit_fees = true;
        toPost.match_ach_fees = true;
      } else {
        toPost.match_fees = false;
        toPost.match_debit_fees = false;
        toPost.match_ach_fees = false;
      }

      if (this.existingAccountId && !_.isEmpty(this.existingAccountName)) {
        try {
          await api({ showError: false }).post(`cst/merchant_accounts/${this.existingAccountId}`, { name: this.existingAccountName });
        } catch {
          this.toast('Existing Merchant Account name could not be updated', false);
          return;
        } finally {
          this.saving = null;
        }
      }

      const isUpdate = this.editing && this.merchantAccount.id;
      try {
        const apiLocal = api({ showError: false });
        const res = await (isUpdate
          ? apiLocal.post(`cst/merchant_accounts/${this.merchantAccount.id}`, toPost)
          : apiLocal.post('cst/merchant_accounts', { ...toPost, club_id: this.clubID })
        );

        if (this.setAsDefault) {
          // Get ID from response in case this is an initial save
          const merchantAccountId = res.data.id;
          await apiLocal.post(`cst/merchant_accounts/${merchantAccountId}/is_default`);
        }

        this.toast(`Merchant Account ${isUpdate ? 'Updated' : 'Enabled'}`);
        this.dismiss({ refreshData: true });
      } catch {
        this.toast(`Merchant Account could not be ${isUpdate ? 'updated' : 'enabled'}`, false);
      } finally {
        this.saving = null;
      }
    },
    dismiss(options) {
      this.$refs.modal.dismiss();
      this.$emit('dismissed', options);
    },
  },

  watch: {
    cardFees: {
      deep: true,
      handler() {
        if (this.cardFees.fee_type !== 'custom') {
          this.cardFees.fee_percent = this.merchantAccount.fee_percent;
          this.cardFees.fee_base_cents = this.merchantAccount.fee_base_cents;
        }
        if (this.cardFees.fee_type === '') {
          this.noServiceFeesForDebit = false;
          this.chargeClubDebitFees = true;
        }
      },
    },
    achFees: {
      deep: true,
      handler() {
        if (this.achFees.fee_type !== 'custom') {
          this.achFees.fee_percent = this.merchantAccount.ach_percent;
          this.achFees.fee_base_cents = this.merchantAccount.ach_base_cents;
          this.achFees.fee_cap = this.merchantAccount.ach_cap;
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.merchant-account-modal
  .form-section
    margin-bottom: 2rem
    &__label
      font-family: $family-headers
      font-weight: $weight-bold
      font-size: $size-6
      margin-bottom: 0.75rem
  .checkboxes
    display: grid
    grid-template-columns: repeat(2, 1fr)
.badge.tag
  font-weight: $weight-semibold
  font-size: $size-7
  font-family: $family-headers
  margin-left: 0.75rem
</style>
